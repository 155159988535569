import * as request from '@/libs/utils/request'
import Config from '@/libs/config'

export function getSchema() {
  return request.get({
    url: "/api/"+ Config.apiversion +"/properties?need_cache=0"
  })
}

export function saveCourse(data) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/courses/properties/update",
    data: data
  })
}

export function renameCourse(course_id,title) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/courses/rename",
    data: {
      course_id,
      title
    }
  })
}

export function getCourseList({keyword, sort_mode, page, limit, ...args}) {
  let url = "/api/"+ Config.apiversion +"/courses/list";
  if(keyword && keyword != undefined && keyword.trim().length > 0) {
    url = url + "?keyword=" + keyword
  }
  if(sort_mode && sort_mode != undefined) {
    url = url.indexOf('?') > -1 ? (url + '&sort_mode=' + sort_mode) : (url + '?sort_mode=' + sort_mode)
  }
  if(page && page != undefined) {
    url = url.indexOf('?') > -1 ? (url + '&page=' + page) : (url + '?page=' + page)
  }
  if(limit && limit != undefined) {
    url = url.indexOf('?') > -1 ? (url + '&limit=' + limit) : (url + '?limit=' + limit)
  }
  if(args) {
    for(let key in args) {
      if(!key || key.indexOf('tag_name_list') == -1) return;
      url = url.indexOf('?') > -1 ? (url + `&${key}=` + args[key]) : (url + `?${key}=` + args[key])
    }
  }
  return request.get({
    url: url
  })
}

export function getCourseDetail(id) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/courses/get?course_id="+id
  })
}

export function getTree(id) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/courses/contentobject/getTree?course_id=" + id,
  })
}

export function createCourse({title,theme_id}) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/courses/create",
    data:{
      title,
      theme_id
    }
  })
}

export function delCourse(id) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/courses/delete",
    data: {
      course_id: id
    }
  })
}

// 复制课程
export function copyCourse(id) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/courses/copy?course_id="+id,
  })
}

export function moveNode({course_id, _parentId, contentobject_id, below_contentobject_id, move_type}) { //contentobject_id:当前移动节点ID; below_contentobject_id: 目标节点id
  let url = "/api/"+ Config.apiversion +"/courses/contentobject/move?course_id="+course_id + "&_parentId=" 
  + _parentId + "&contentobject_id=" + contentobject_id + "&move_type=" + move_type ;
  if(below_contentobject_id) {
    url = url + "&below_contentobject_id=" + below_contentobject_id
  }
  return request.get({
    url: url
  })
}

// 课程封面修改
export function updateCourseCover({course_id, cover}) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/courses/cover/update",
    data: {
      course_id,
      cover
    }
  })
}

// 全局设置-获取所有配置
export function getGlobalSetting() {
  return request.get({
    url: "/api/"+ Config.apiversion +"/global/setting/get",
  })
}

// 全局设置-完成条件-获取
export function getCompletionCondition(course_id) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/global/completionCondition/get?course_id="+course_id,
  })
}

// 全局设置-完成条件-提交
export function updateCompletionCondition({course_id, _requireContentCompleted, _requireAssessmentCompleted }) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/global/completionCondition/update",
    data: {
      course_id,
      _requireContentCompleted,
      _requireAssessmentCompleted
    }
  })
}

// 全局设置-首页设置-获取配置
export function getHomeSetting(course_id) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/global/homeSetting/get?course_id=" + course_id,
  })
}

// 全局设置-首页设置-提交
export function putHomeSetting({course_id, _startIds, _force, _isMenuDisabled}) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/global/homeSetting/update",
    data: {
      course_id,
      _startIds,
      _force,
      _isMenuDisabled
    }
  })
}

// 全局设置-按钮文字-获取
export function getButtonText(course_id) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/global/buttonText/get?course_id=" + course_id,
  })
}

// 全局设置-按钮文字-提交
export function updateButtonText({course_id, _submit, _reset, _showCorrectAnswer, _hideCorrectAnswer, _showFeedback, remainingAttemptsText, remainingAttemptText }) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/global/buttonText/update",
    data: {
      course_id,
      _submit,
      _reset,
      _showCorrectAnswer,
      _hideCorrectAnswer,
      _showFeedback,
      remainingAttemptsText,
      remainingAttemptText
    }
  })
}

// 全局设置-弹出动画-获取
export function getPopupAnimation(course_id) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/global/popupAnimation/get?course_id=" + course_id,
  })
}

// 全局设置-弹出动画-提交
export function updatePopupAnimation({course_id, _showEasing, _hideEasing, _duration }) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/global/popupAnimation/update",
    data: {
      course_id,
      _showEasing,
      _hideEasing,
      _duration,
    }
  })
}

// 全局设置-屏幕尺寸-获取
export function getScreenSize(course_id) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/global/screenSize/get?course_id=" + course_id,
  })
}

// 全局设置-屏幕尺寸-提交
export function updateScreenSize({course_id, large, small }) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/global/screenSize/update",
    data: {
      course_id,
      large,
      small,
    }
  })
}

// 全局设置-书签设置-获取
export function getBookmarkSettings(course_id) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/global/bookmarkSettings/get?course_id=" + course_id,
  })
}

// 全局设置-书签设置-提交
export function updateBookmarkSettings({course_id, _isEnabled, _level, _showPrompt, title, body, yes, no }) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/global/bookmarkSettings/update",
    data: {
      course_id,
      _isEnabled,
      _level,
      _showPrompt,
      title,
      body,
      yes,
      no
    }
  })
}

// 全局设置-辅助资源-获取
export function getAuxiliaryResources(course_id) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/global/auxiliaryResources/get?course_id=" + course_id,
  })
}

// 全局设置-辅助资源-提交
export function updateAuxiliaryResources({course_id, _isEnabled, title, 
    description, _resourcesItems, _filterButtons,
    _filterAria, _drawerOrder, typeFilter_media,
    typeFilter_link, typeFilter_document }) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/global/auxiliaryResources/update",
    data: {
      course_id,
      _isEnabled,
      _resourcesItems,
      description,
      title,
      _filterButtons,
      _filterAria,
      _drawerOrder,
      typeFilter_media,
      typeFilter_link,
      typeFilter_document
    }
  })
}

// 获取课程-标签-列表
export function getCourseTagList(tag_name) {
  let url = "/api/"+ Config.apiversion +"/courses/tag/list";
  if(tag_name && tag_name.trim()) {
    url = url + '?tag_name=' + tag_name;
  }
  return request.get({
    url: url
  })
}

// 新增 课程标签
export function addCourseTag(tag_name) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/courses/tag/add",
    data: {
      tag_name
    }
  })
}

//  更新课程标签
export function updateCourseTag({course_id, tag_name_list}) { //tag_name_list: 标签ID数组,传空值则等于清空标签
  return request.post({
    url: "/api/"+ Config.apiversion +"/courses/updateCourseTag",
    data: {
      course_id,
      tag_name_list
    }
  })
}

// 分享中心课程列表
export function getShareCourseList({keyword, sort_mode, page, limit, ...args}) {
  let url = "/api/"+ Config.apiversion +"/user/sharecourse/list";
  if(keyword && keyword != undefined && keyword.trim().length > 0) {
    url = url + "?keyword=" + keyword
  }
  if(sort_mode && sort_mode != undefined) {
    url = url.indexOf('?') > -1 ? (url + '&sort_mode=' + sort_mode) : (url + '?sort_mode=' + sort_mode)
  }
  if(page && page != undefined) {
    url = url.indexOf('?') > -1 ? (url + '&page=' + page) : (url + '?page=' + page)
  }
  if(limit && limit != undefined) {
    url = url.indexOf('?') > -1 ? (url + '&limit=' + limit) : (url + '?limit=' + limit)
  }
  if(args) {
    for(let key in args) {
      if(!key || key.indexOf('tag_name_list') == -1) return;
      url = url.indexOf('?') > -1 ? (url + `&${key}=` + args[key]) : (url + `?${key}=` + args[key])
    }
  }
  return request.get({
    url: url
  })
}

// 选择课程
export function getShareCourse() {
  return request.get({
    url: "/api/"+ Config.apiversion +"/user/sharecourse/choose",
  })
}

// 分享设置
export function setShare({set_type,id,open_way,code_type,code,teloremail,valid_type,valid_time,valid_days}) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/user/sharecourse/set",
    data:{
      set_type,
      id,
      open_way,
      code_type,
      code,
      teloremail,
      valid_type,
      valid_time,
      valid_days
    }
  })
}
// 确定分享
export function confirmShare({course_id,open_way,code_type,code,teloremail,valid_type,valid_time,valid_days}) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/user/sharecourse/share",
    data:{
      course_id,
      open_way,
      code_type,
      code,
      teloremail,
      valid_type,
      valid_time,
      valid_days
    }
  })
}
// 设置详情
export function SetShareDetail(id) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/user/sharecourse/detail?id="+id,
  })
}
// 预览课程（分享)
export function previewShareCourse(p) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/user/sharecourse/preview?p="+p,
  })
}